import React from "react";
import './App.css';
import GlobalStyles from 'styles/GlobalStyles';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from './components/headers/Header';
import Home from './pages/home';
import ContactUs from "pages/ContactUs";
import AboutUs from "pages/AboutUs";

export default function App() {
  return (
    <div>
      <GlobalStyles />
      <Router >
        <Header roundedHeaderButton={true} />
        <Routes>
          <Route exact path='/' element={< Home />}></Route>
          <Route exact path='/contactus' element={< ContactUs />}></Route>
          <Route exact path='/aboutus' element={< AboutUs />}></Route>
          <Route exact path='/index.html' element={< Home />}></Route>
        </Routes>

      </Router>

      {/* <AnimationRevealPage>
      <Footer />
      </AnimationRevealPage> */}
    </div>
  );
}

