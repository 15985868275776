import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Footer from "components/footers/Footer";
import Features from "components/features/DashedBorderSixFeatures";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import ImacproImage from "images/iphone/macbook.svg";
import client1 from "images/ourclients/01.jpg";
import client2 from "images/ourclients/03.jpg";

export default function Home() {
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-blue-400`;

    return (
        <AnimationRevealPage>
            <Hero roundedHeaderButton={true} />
            <Features
                subheading=""
                heading={
                    <>
                        Most Common <HighlightedText>iPhone</HighlightedText> repairs.
                    </>
                }
            />
            <FeatureWithSteps
                subheading=""
                heading={
                    <>
                        Get your device <HighlightedText>Repaired.</HighlightedText>
                    </>
                }
                textOnLeft={false}
                imageSrc={ImacproImage}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            />
            <Testimonial
                subheading=""
                heading={
                    <>
                        Our Clients <HighlightedText>Love Us.</HighlightedText>
                    </>
                }
                testimonials={[
                    {
                        stars: 5,
                        profileImageSrc: client1,
                        heading: "Excellent Service",
                        quote:
                            "Good experience. Good service Excellent job done by the technician, great communication.Freely picked my device delivered on time, was negotiable in the services offered and completed on time. Thanks Vikram",
                        customerName: "SaiKrishna Velamuri",
                        customerTitle: ""
                    },
                    {
                        stars: 5,
                        profileImageSrc: client2,
                        heading: "Amazing Experience",
                        quote:
                            "I had a great experience with iPro Services Phone having battery issue. Those Peoples are Fix my phone Problem with in 4 Hours. Mainly Vikram is the Master of Fixing the Apple Phone Issue i feel very Happy after the service now my Phone is working very well...",
                        customerName: "Meganath Reddy",
                        customerTitle: ""
                    }
                ]}
            />
            {/* <GetStarted /> */}
            <Footer />
        </AnimationRevealPage>
    );
}
