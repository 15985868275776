import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import chargingport from "../../images/iphone/chargingport.jpg";
import screenreplacement from "../../images/iphone/screenreplacement.jpeg";
import camera from "../../images/iphone/camera.webp";
import battery from "../../images/iphone/battery.png";
import ReliableIconImage from "../../images/reliable-icon.svg";
import software from "../../images/iphone/software.jpg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-2`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-blue-400 rounded-lg mt-12`}
  .imageContainer {
    ${tw` text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-20 h-20 rounded-full`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-blue-400`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ heading = "Most common iphone repairs", subheading = "", description = "" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: screenreplacement,
      title: "Screen Replacement",
      description: "Get high quality screen replacement."
    },
    {
      imageSrc: chargingport, title: "Charging Port",
      description: "Mobile not charging even when connected to charger"
    },
    {
      imageSrc: camera, title: "Camera",
      description: "Front or back camera showing blank or blurry screen"
    },
    {
      imageSrc: battery, title: "Battery replacemnt",
      description: "Mobile battery discharging soon or bulged"
    },
    {
      imageSrc: ReliableIconImage, title: "Microphone",
      description: "Diagnose Mobile microphone if your caller is not able to hear you"
    },
    {
      imageSrc: software, title: "Software issue",
      description: "Troubleshooting for mobile Software Issues"
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>{heading}</Heading>
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Get repaired with high quality."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
